import style from "./Blocks.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FaChevronLeft, FaChevronRight, FaPause, FaPlay } from "react-icons/fa";
import Slider from "react-slick";
import { useState } from "react";
import useData from "../../../../hooks/useData";
import { HashLink } from "react-router-hash-link";

const Blocks = () => {
  const [isPlaying, setIsPlaying] = useState(true); // stan kontrolujący slider
  const banner = useData("HeadPage_block_slider");

  const data = [
    {
      title: useData("HeadPage_block_block_title_1"),
      link: useData("HeadPage_block_block_link_1"),
      icon: useData("HeadPage_block_block_ikon_1"),
    },
    {
      title: useData("HeadPage_block_block_title_2"),
      link: useData("HeadPage_block_block_link_2"),
      icon: useData("HeadPage_block_block_ikon_2"),
    },
    {
      title: useData("HeadPage_block_block_title_3"),
      link: useData("HeadPage_block_block_link_3"),
      icon: useData("HeadPage_block_block_ikon_3"),
    },
    {
      title: useData("HeadPage_block_block_title_4"),
      link: useData("HeadPage_block_block_link_4"),
      icon: useData("HeadPage_block_block_ikon_4"),
    },
    {
      title: useData("HeadPage_block_block_title_5"),
      link: useData("HeadPage_block_block_link_5"),
      icon: useData("HeadPage_block_block_ikon_5"),
    },
    {
      title: useData("HeadPage_block_block_title_6"),
      link: useData("HeadPage_block_block_link_6"),
      icon: useData("HeadPage_block_block_ikon_6"),
    },
  ];

  const ids = banner.split(",");

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <FaChevronRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <FaChevronLeft />
      </div>
    );
  }

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying); // przełącznik pauzy/play
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipe: false,
    slidesToScroll: 1,
    autoplay: isPlaying, // kontrolowanie autoplay na podstawie stanu
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className={style.container}>
      <div className={style.left}>
        {/* Przycisk pauzy/play */}
        <button
          className={style.playPauseButton}
          onClick={togglePlayPause}
          aria-label={isPlaying ? "Pause slider" : "Play slider"} // WCAG
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        {ids.length > 1 ? (
          <Slider {...settings}>
            {ids.map((id, index) => {
              return (
                <div key={index} className={style.sliderImage}>
                  <div>
                    <img
                      src={`${process.env.REACT_APP_LINKTOAPI2}/getImage/${id}`}
                      alt="Slider image 1"
                    />
                    <img
                      src={`${process.env.REACT_APP_LINKTOAPI2}/getImage/${id}`}
                      alt="Slider image 1"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : ids[0] ? (
          <div className={[style.sliderImage, style.oneImage].join(" ")}>
            <div>
              <img
                src={`${process.env.REACT_APP_LINKTOAPI2}/getImage/${ids[0]}`}
                alt="Slider image 1"
              />
              <img
                src={`${process.env.REACT_APP_LINKTOAPI2}/getImage/${ids[0]}`}
                alt="Slider image 1"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={style.right}>
        {data.map((data, key) => {
          return (
            <HashLink
              aria-label={"Link do " + data.title}
              to={data.link}
              className={style.block}
              key={key}
            >
              <div className={style.image}>
                <img
                  aria-label={"ikona dekoracyjna"}
                  src={
                    process.env.REACT_APP_LINKTOAPI2 + "getImage/" + data.icon
                  }
                />
              </div>
              <div className={style.text}>
                <h5 dangerouslySetInnerHTML={{ __html: data.title }}></h5>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </HashLink>
          );
        })}
      </div>
    </div>
  );
};

export default Blocks;
