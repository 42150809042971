import style from "./QrCode.module.css";
import QRCode from "react-qr-code";

const QrCode = () => {
  return (
    <div className={style.container}>
      <h4>Wersja mobilna:</h4>
      <QRCode
        size={256}
        className={style.qrCode}
        value={window.location.href}
        fgColor={"#e1e1e1"}
        bgColor={"rgba(178,181,187,0.03)"}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};
export default QrCode;
