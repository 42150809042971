import React from "react";
import { animated, useSpring } from "@react-spring/web";
import style from "./ComponentMainButton.module.css";
import deer from "./../../assets/images/deer.png";

const ComponentMainButton = (props) => {
  const {
    content,
    color,
    styleClass,
    disabled,
    textColor = "white",
    width,
    widthOfBorder,
    animation = false,
  } = props;

  // Stan do śledzenia, czy kursor znajduje się nad przyciskiem
  const [hovered, setHovered] = React.useState(false);

  // Ustawienia animacji obrazka jelenia
  const deerAnimation = useSpring({
    left: hovered ? "50px" : "200px", // Wlatuje na środek i wraca poza przycisk
    top: hovered ? "-40px" : "-200px", // Wlatuje na środek i wraca poza przycisk
    opacity: hovered ? 1 : 0, // Zmiana opacity
    config: { duration: 1200, damping: 1 }, // Kontrolowana szybkość animacji
  });

  return (
    <button
      disabled={disabled}
      className={`${style.btnKnowMore} ${styleClass}`}
      style={{
        color: textColor,
        backgroundColor: color,
        width: width || "",
        border: `1px solid ${widthOfBorder}`,
      }}
      onClick={props.clickEvent}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {animation && (
        <animated.img
          src={deer}
          alt="Icon"
          style={deerAnimation}
          className={style.deerIcon}
        />
      )}

      {content}
      {/*<div className={style.emptyBlock}></div>*/}
    </button>
  );
};

export default ComponentMainButton;
