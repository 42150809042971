import React, { useEffect, useState } from "react";
import style from "./DonationAndAdvice.module.css";
import ComponentMainButton from "../ComponentMainButton/ComponentMainButton";

// Import obrazów
//import summerImage from "./../../assets/images/beige.jpg";
const DonationAndAdvice = (props) => {
  const [animation, setAnimation] = useState(false);

  const title = props.title;
  const whichFunction = props.onClickEvent;

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (
      currentMonth === 10 ||
      currentMonth === 11 ||
      currentMonth === 0 ||
      currentMonth === 1 ||
      currentMonth === 2
    ) {
      setAnimation(true);
      setBackgroundImage(style.winter);
    } else {
      setBackgroundImage(style.summer);
    }
  }, []);

  return (
    <a
      href="#"
      className={[style.container, backgroundImage].join(" ")}
      onClick={whichFunction}
      aria-label={props.ariaLabel}
    >
      <div className={style.content}>
        <h3>{title}</h3>
        <ComponentMainButton
          content="Dokładniej"
          color="#baa56a"
          clickEvent={whichFunction}
          styleClass={style.mainBtn}
          widthOfBorder="rgb(167 167 167)"
          animation={animation}
        />
      </div>
    </a>
  );
};

export default DonationAndAdvice;
