import style from "./Footer.module.css";
import OpenHours from "./OpenHours/OpenHours";
import VisitCounter from "./VisitCounter/VisitCounter";
import QrCode from "./QrCode/QrCode";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BiLogoFacebook } from "react-icons/bi";

const Footer = () => {
  const [year, setYear] = useState();

  useEffect(() => {
    const data = new Date();
    const year = data.getFullYear();
    setYear(year);
  }, []);

  function desplayAdminPanel() {
    window.location.href = "https://www.admin.kanczuga.ovh";
  }

  const [deklaracja, setDeklaracja] = useState(null);
  const data = useSelector((state) => state.websiteDates.templateSettings);
  useEffect(() => {
    if (data && data.length > 0) {
      let name = data.find((k) => k.id == 218).value;
      axios(process.env.REACT_APP_LINKTOAPI2 + "pre_getFileId/" + name)
        .then((resp) => {
          setDeklaracja(resp.data.data[0].id);
        })
        .catch((error) => {});
    }
  }, [data]);

  if (!data || data.length < 1) {
    return <h4>Loading....</h4>;
  }

  return (
    <>
      <div className={style.fale}></div>

      <div className={style.container} id="footer">
        <div className={style.mainContainer} id="contact">
          <div className={style.blockForLogoAndContact}>
            <p
              dangerouslySetInnerHTML={{
                __html: data.find((k) => k.name == "footer_panel_4").value,
              }}
            ></p>
            {/*<Contacts/>*/}
            {/*<LogoOfWebsite/>*/}
          </div>
          <OpenHours />
          <VisitCounter />
          <QrCode />
          <div className={style.blockForFbIcons}>
            <NavLink
              to={data.find((k) => k.name == "Facebook_link").value}
              aria-label={"Link do pierwszego konta facebook instytucji"}
            >
              <BiLogoFacebook className={style.firstIconFb} />
            </NavLink>
            <NavLink
              to={data.find((k) => k.name == "Facebook_link_2").value}
              aria-label={"Link do drugiego konta facebook instytucji"}
              className={style.fb2}
            >
              <BiLogoFacebook className={style.secondIconFb} />
            </NavLink>
          </div>
          <div className={style.blockOflinkToDeclaration}>
            <a
              target="_blank"
              href={process.env.REACT_APP_LINKTOAPI2 + "getFile/" + deklaracja}
            >
              <span>Deklaracja dostępności</span>
            </a>
            <NavLink to={"/pageMap"}>
              <span>Mapa strony</span>
            </NavLink>
          </div>
        </div>
        <div className={style.grayBelt}>
          <div>
            <span> &copy; Wszelkie prawa zastrzeżone {year}</span>
          </div>
          <FontAwesomeIcon
            icon={faUserTie}
            className={style.adminIcon}
            onClick={desplayAdminPanel}
          />
        </div>
      </div>
    </>
  );
};
export default Footer;
