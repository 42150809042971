import style from "./AboutUs.module.css";
import Content from "./Content/Content";
import { useEffect, useState } from "react";

const AboutUs = () => {
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (
      currentMonth === 10 ||
      currentMonth === 11 ||
      currentMonth === 0 ||
      currentMonth === 1 ||
      currentMonth === 2
    ) {
      setBackgroundImage(style.winter);
    } else {
      setBackgroundImage(style.summer);
    }
  }, []);
  return (
    <div className={`${style.container}  ${backgroundImage}`} id="aboutUs">
      {/*<Content data={data} />*/}
      <Content />
      {/*<img  className={style.img}src={aboutImage} alt="aboutUs" />*/}
    </div>
  );
};
export default AboutUs;
